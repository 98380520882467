﻿$(function () {
    if (window.bauer && window.bauer.affiliateComponents.getProductCardManager) {
        engage();
    } else {
        $(document).on('bauer.affiliate-components.product-card.loaded', function () {
            engage();
        });
    }

    function engage() {
        $('.product-card__alternative-product-listings__merchant-link').each(function (index, element) {
            var $link = $(element);

            var productCardManager = window.bauer.affiliateComponents.getProductCardManager($link);

            $link.off('click').on('click', function () {
                productCardManager.FirePermutiveEvent($link);
            });
        });
    }
});