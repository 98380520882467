﻿$(function () {
    if (window.bauer && window.bauer.affiliateComponents.getProductCardManager) {
        engage();
    } else {
        $(document).on('bauer.affiliate-components.product-card.loaded', function () {
            engage();
        });
    }

    function engage() {
        $('.product-card__secondary-image-carousel').each(function (imageCarouselIndex, imageCarouselElement) {
            var $imageCarouselElement = $(imageCarouselElement);

            var productCardManager = window.bauer.affiliateComponents.getProductCardManager($imageCarouselElement);

            $imageCarouselElement.find('.product-card__secondary-image-carousel__picture').each(function (pictureIndex, pictureElement) {
                var $pictureElement = $(pictureElement);

                $pictureElement.off('click').on('click', function () {
                    productCardManager.ShowImageModalAndSetImage(pictureIndex);
                });
            });
        });
    }
});