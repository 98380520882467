﻿$(function () {
    $('.product-card__picture').each(function (index, element) {
        var $container = $(element);

        // find any spinners associated with the image
        $container.find('.product-card__picture__spinner').each(function (spinnerIndex, spinnerElement) {
            // if there is a spinner associated with the image, find the image
            $container.find('img').each(function (imgIndex, imgElement) {
                if (!imgElement.complete) {
                    var $imgElement = $(imgElement);

                    $container.addClass('product-card__picture--loading');

                    $imgElement.on('load', function () {
                        $container.removeClass('product-card__picture--loading');
                    });
                }
            });
        });
    });
}); 