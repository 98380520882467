﻿function ImageModalManager() {
    var $primaryImages = [];
    var selectedIndex = -1;

    function showPrimaryImage(imageIndex) {
        var $imageElement = $primaryImages[imageIndex];

        if ($imageElement) {
            $imageElement.addClass('product-card__image-modal__main-image__picture--show');
        }
    }

    function hidePrimaryImage(imageIndex) {
        var $imageElement = $primaryImages[imageIndex];

        if ($imageElement) {
            $imageElement.removeClass('product-card__image-modal__main-image__picture--show');
        }
    }

    this.AddPrimaryImage = function (imageIndex, $imageElement) {
        $primaryImages[imageIndex] = $imageElement;
    }

    this.SelectPrimaryImage = function (imageIndex) {
        if (imageIndex != selectedIndex) {
            if (selectedIndex != -1) {
                hidePrimaryImage(selectedIndex);
            }

            showPrimaryImage(imageIndex);

            selectedIndex = imageIndex;
        }
    }
}

$(function () {
    if (window.bauer && window.bauer.affiliateComponents.getProductCardManager) {
        engage();
    } else {
        $(document).on('bauer.affiliate-components.product-card.loaded', function () {
            engage();
        });
    }

    function engage() {
        $('.product-card__image-modal').each(function (imageModalIndex, imageModalElement) {
            var imageModalManager = new ImageModalManager();

            var $imageModalElement = $(imageModalElement);

            // get the product card containing this modal
            var productCardManager = window.bauer.affiliateComponents.getProductCardManager($imageModalElement);

            var closeButton = $imageModalElement.find('.product-card__image-modal__close')[0];

            if (closeButton) {
                var $closeButton = $(closeButton);

                $closeButton.off('click').on('click', function (event) {
                    productCardManager.HideImageModal();
                });
            }

            $imageModalElement.find('.product-card__image-modal__main-image__picture').each(function (imageIndex, imageElement) {
                var $imageElement = $(imageElement);
                var index = $imageElement.data('index');
                imageModalManager.AddPrimaryImage(index, $imageElement);
            });

            $imageModalElement.find('.product-card__image-modal__footer__carousel__picture').off('click').on("click", function (element) {
                var $element = $(element.currentTarget);
                var index = $element.data('index');
                imageModalManager.SelectPrimaryImage(index);
            });

            // event to set the index of the picture to display
            $imageModalElement.off('set-index').on('set-index', function (event, index) {
                imageModalManager.SelectPrimaryImage(index);
            });

            imageModalManager.SelectPrimaryImage(0);
        });
    }
});