﻿// tab manager object for localising tab management
function TabManager() {
    var tabLookup = {};

    var activeTabIndex = -1;

    function setActive(index) {
        if (tabLookup[index] && tabLookup[index].$tab && tabLookup[index].$content) {
            tabLookup[index].$tab.addClass('product-card__tab-control__tabs__tab-name--active');
            tabLookup[index].$content.addClass('product-card__tab-control__tab-content--active');
        }
    }

    function setInactive(index) {
        if (tabLookup[index] && tabLookup[index].$tab && tabLookup[index].$content) {
            tabLookup[index].$tab.removeClass('product-card__tab-control__tabs__tab-name--active');
            tabLookup[index].$content.removeClass('product-card__tab-control__tab-content--active');
        }
    }

    this.AddTab = function(index, $tab) {
        tabLookup[index] = tabLookup[index] ? tabLookup[index] : {};
        tabLookup[index].$tab = $tab;
    }

    this.AddTabContent = function (index, $tabContent) {
        tabLookup[index] = tabLookup[index] ? tabLookup[index] : {};
        tabLookup[index].$content = $tabContent;
    }

    this.ActivateTab = function (index) {
        if (index !== activeTabIndex) {
            if (activeTabIndex !== -1) {
                setInactive(activeTabIndex);
            }

            setActive(index);

            activeTabIndex = index;
        }
    }
}

$(function () {
    $('.product-card__tab-control').each(function (index, element) {
        var $tabControl = $(element);
        var tabManager = new TabManager();

        $tabControl.find('.product-card__tab-control__tabs button').each(function (buttonIndex, buttonElement) {
            var $buttonElement = $(buttonElement);

            tabManager.AddTab(buttonIndex, $buttonElement);

            $buttonElement.off('click').on('click', function () {
                tabManager.ActivateTab(buttonIndex);
            });
        });

        $tabControl.find('.product-card__tab-control__tab-content').each(function (contentIndex, contentElement) {
            var $contentElement = $(contentElement);

            tabManager.AddTabContent(contentIndex, $contentElement);
        });

        tabManager.ActivateTab(0);
    });
});