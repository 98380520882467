﻿function ModalManager($modalElement) {
    function showModal() {
        $modalElement.addClass('product-card__modal--visible');
    }

    function hideModal() {
        $modalElement.removeClass('product-card__modal--visible');
    }

    this.Show = function () {
        showModal();
    }

    this.Hide = function () {
        hideModal();
    }
}

$(function () {
    $('.product-card__modal').each(function (modalIndex, modalElement) {
        var $modalElement = $(modalElement);
        var modalManager = new ModalManager($modalElement);
        $modalElement.on('show-modal', modalManager.Show);
        $modalElement.on('hide-modal', modalManager.Hide);
    });
});