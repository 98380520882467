﻿$(function () {
    $('.product-card__ga-link').each(function (linkIndex, linkElement) {
        var $linkElement = $(linkElement);

        var gaCategory = $linkElement.data('ga-category');

        if (gaCategory) {
            $linkElement.on('click', function (event) {
                // send the click event here
                console.log(gaCategory);
            });
        };
    });
});