﻿$(function () {
    if (window.bauer && window.bauer.affiliateComponents.getProductCardManager) {
        engage();
    } else {
        $(document).on('bauer.affiliate-components.product-card.loaded', function () {
            engage();
        });
    }

    function engage() {
        $('.product-card__product-image').each(function (imageIndex, imageElement) {
            var $imageElement = $(imageElement);

            var productCardManager = window.bauer.affiliateComponents.getProductCardManager($imageElement);

            $imageElement.find('.js-image-modal-trigger').off('click').on('click', function (index, element) {
                productCardManager.ShowImageModal();
            });
        });
    }
});