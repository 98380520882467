$(function () {
    $('.product-card-anchor').each(function (readReviewIndex, readReviewElement) {
        // Get the Id of the anchor link inside this read review element.
        const anchorId = $(readReviewElement).find('a').attr('id');

        // When Read Review is clicked, scroll the product card with the matching Id into view.
        $(readReviewElement).on('click', function () {
            const productCardId = `#${anchorId}-anchor`;

            $('html, body').animate({
                scrollTop: $(productCardId).offset().top
            }, 500);

            return false;
        });
    });
});