﻿// product card manager - tool for managing the resources of an individual product card
function ProductCardManager($productCardElement) {
    function displayImageModal() {
        $productCardElement.find('.product-card__modal').each(function (modalIndex, modalElement) {
            $(modalElement).trigger('show-modal');
        });
    }

    // shows the image modal keeping the last selected image
    this.ShowImageModal = function () {
        displayImageModal();
    }

    // shows the image modal and selects the image with the passed index
    this.ShowImageModalAndSetImage = function (index) {
        // set the index of the image to display in the modal
        $productCardElement.find('.product-card__image-modal').each(function (imageModalIndex, imageModalElement) {
            $(imageModalElement).trigger('set-index', [index]);
        })

        displayImageModal();
    }

    this.HideImageModal = function () {
        $productCardElement.find('.product-card__modal').each(function (modalIndex, modalElement) {
            $(modalElement).trigger('hide-modal');
        });
    }

    this.FirePermutiveEvent = function ($element) {
        var product = $element.data('permutive-product');

        if (window.permutive && product) {
            var $link = $element.is('a') ? $element : $element.find('a').first();
            var url = $link.attr('href');

            window.permutive.track('AffiliateLinkClick', {
                type: 'product-card',
                dest_url: url,
                product: product
            });
        }
    }
}

window.bauer = window.bauer || {};
window.bauer.affiliateComponents = window.bauer.affiliateComponents || {};

window.bauer.affiliateComponents.getProductCardManager = function getProductCardManager($element) {
    var productCardElement = $element.closest('.product-card');
    var productCardManager = null;

    if (productCardElement) {
        var $productCardElement = $(productCardElement);
        productCardManager = $productCardElement.data('manager');

        if (!productCardManager) {
            productCardManager = new ProductCardManager($productCardElement);
            $productCardElement.data('manager', productCardManager);
        }
    }

    return productCardManager;
};

$(document).trigger('bauer.affiliate-components.product-card.loaded');