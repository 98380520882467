﻿function CarouselManager($carousel, $content, $container, $scrollLeftButtons, $scrollRightButtons) {
    var elementIndex = 0;

    var $elements = [];

    const buttonDisabledClass = 'product-card__carousel__arrow--disabled';

    // anchor the container so the element positions are set correctly
    $container.css('transform', 'translate(0px)');

    $scrollLeftButtons.each(function (buttonIndex, buttonElement) {
        $(buttonElement).off("click").on("click", scrollLeft);
    });

    $scrollRightButtons.each(function (buttonIndex, buttonElement) {
        $(buttonElement).off("click").on("click", scrollRight);
    });

    // create an intersection observer to see if the last element is in the window
    var intersectionObserver = new IntersectionObserver(inWindow, {
        root: $content[0],
        rootMargin: '0px',
        threshold: 1.0
    });

    function inWindow(entries) {
        for (var i = 0; i < entries.length; i++) {
            var entry = entries[i];
            var $element = $(entry.target);
            var elementIndex = $element.data('element-index');

            // if the visibility of the first element has changed
            if (elementIndex == 0) {
                // if the first element is in the window, don't allow scrolling right
                entry.isIntersecting ? disableScrollRightButtons() : enableScrollRightButtons();
            }

            // if the visibility of the last element has changed
            if (elementIndex == $elements.length - 1) {
                // if the last element is in the window, don't allow scrolling left
                entry.isIntersecting ? disableScrollLeftButtons() : enableScrollLeftButtons();
            }
        }
    }

    function scrollContainer(position) {
        $container.css('transform', 'translate(' + position + 'px)');
    }

    function scrollTo(index) {
        if (index >= 0 && index < $elements.length) {
            var $element = $elements[index];
            var elementLeft = $element.position().left;

            var offset = 0 - elementLeft;
            scrollContainer(offset);
        }
    }

    function scrollRight() {
        if (elementIndex > 0) {
            elementIndex--;
            scrollTo(elementIndex);
        }
    }

    function scrollLeft() {
        if (elementIndex < $elements.length - 2) {
            elementIndex++;
            scrollTo(elementIndex);
        }
    }

    function disableScrollLeftButtons() {
        $scrollLeftButtons.addClass(buttonDisabledClass).prop('disabled', true);
    }

    function enableScrollLeftButtons() {
        $scrollLeftButtons.removeClass(buttonDisabledClass).prop('disabled', false);
    }

    function disableScrollRightButtons() {
        $scrollRightButtons.addClass(buttonDisabledClass).prop('disabled', true);
    }

    function enableScrollRightButtons() {
        $scrollRightButtons.removeClass(buttonDisabledClass).prop('disabled', false);
    }

    this.AddElement = function (index, element) {
        var $element = $(element);
        $elements[index] = $element;
        $element.data('element-index', index);

        // observe the element so we can enable/disable scroll buttons
        intersectionObserver.observe(element);
    }
}

$(function () {
    $('.product-card__carousel').each(function (carouselIndex, carouselElement) {
        var $carouselElement = $(carouselElement);

        // bit confusing - the arrow left buttons scroll the carousel to the right
        var moveRightButtons = $carouselElement.find('.product-card__carousel__arrow--left');

        // bit confusing - the arrow right buttons scroll the carousel to the left
        var moveLeftButtons = $carouselElement.find('.product-card__carousel__arrow--right');

        var container = $carouselElement.find('.product-card__carousel__element-container')[0];

        var content = $carouselElement.find('.product-card__carousel__content')[0];

        var carouselManager = new CarouselManager($carouselElement, $(content), $(container), moveLeftButtons, moveRightButtons);

        $carouselElement.find('.product-card__carousel__element').each(function (carouselElementIndex, carouselElementElement) {
            carouselManager.AddElement(carouselElementIndex, carouselElementElement);
        });
    });
});